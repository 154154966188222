import * as React from 'react'
import * as Rebass from 'rebass'
import styled, { createGlobalStyle } from 'styled-components'
import { graphql } from 'gatsby'
import { path } from 'ramda'

import { Layout } from '../components/layout'
import { Wrapper } from '../components/wrapper'
import { Container } from '../components/container'

const PageTitle = styled(Rebass.Text).attrs({
  fontFamily: 'Avenir',
  fontSize: [5]
})``

const PageHeader = styled(Rebass.Flex).attrs({
  flexDirection: 'column',
  py: 4
})``

const Document = styled.div`
  font-family: Avenir;
  h1,
  h2,
  h3 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  p {
    margin-top: 1em;
  }
`

export default ({ data }) => {
  const documentName = path(['allContentfulDocument', 'documentName'], data)
  const documentBody = path(
    ['allContentfulDocument', 'documentBody', 'childMarkdownRemark', 'html'],
    data
  )
  return (
    <Layout>
      <Wrapper>
        <PageHeader>
          <PageTitle>{documentName}</PageTitle>
        </PageHeader>
        <Container>
          <Document dangerouslySetInnerHTML={{ __html: documentBody }} />
        </Container>
      </Wrapper>
    </Layout>
  )
}

// export const query = graphql`
//   query($slug: String!) {
//     allContentfulDocument(slug: { eq: $slug }) {
//       slug
//       documentName
//       documentBody {
//         childMarkdownRemark {
//           timeToRead
//           html
//           excerpt(pruneLength: 320)
//         }
//       }
//     }
//   }
// `
